@mixin box {
  // max-height: 250px;
  text-align: center;
  background: var(--white);
  // border: 2px solid var(--white);
  box-shadow: 5px 5px 10px rgba(var(--gray_9), 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  height: 100%;
  border: 2px solid var(--white);
  background: var(--white);
  backdrop-filter: blur(20px);
}

.box {
  @include box;
}

.Customer360Analytic {
  margin: 0 10px;
  padding: var(--sp_lv_4);
  min-width: 1000px;
  .analytics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box {
    &.activities {
      .title-wrapper {
        h5 {
          margin-right: 7px;
          margin-bottom: 0px;
        }
      }
    }
    .title-wrapper {
      h5.ant-typography {
        color: #3d5a80;
      }
    }
  }
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 300px);
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 10px;

  .ant-typography {
    color: var(--secondary_blue);
  }

  .box {
    @include box;
    min-height: 250px;
    min-width: 100px;
    grid-row: span 1;
    transition: 0.2s;

    &.is-expanded {
      height: fit-content;
      position: relative;
      z-index: 200;
      box-shadow: 10px 10px 10px rgba(var(--gray_9), 0.3);
    }
  }
}

.product-slider {
  margin: 20px 0;
  width: 100%;
}
